import { Col, Row, OverlayTrigger, Tooltip, Offcanvas } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import Card from "@components/Card";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { useEffect, useState, use } from "react";
import useService from "@hooks/useService";
import ButtonOw from "@components/ButtonOw";
import Image from 'react-bootstrap/Image';
import Badge from 'react-bootstrap/Badge';
import SelectEmployee from "@components/SelectEmployee";
import NotFound from "@components/NotFound";
import Loading from '@components/Loading'
import PaginationOw from '@components/PaginationOw'
import Tour from '@components/Tour'
import { toast } from "react-toastify";
import { money, findMonth, formatDate } from '../../../../helpers'



export default function NFes() {
    const taxInvoicesService = useService('taxInvoices');
    const employeeService = useService("users");
    const [page, setPage] = useState(1)
    const [selectedNF, setSelectedNF] = useState({})
    const [loadingView, setLoadingView] = useState(false)
    const [loading, setLoading] = useState(false)
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('user_ow')))
    const [lastEmp, setLastEmp] = useState(0)
    const [totalEmp, setTotalEmp] = useState(0)
    const pendenciesTax = totalEmp - lastEmp
    const [paginationData, setPaginationData] = useState({
        totalRegisters: 0
    });
    const [form, setForm] = useState({
        userId: '',
        search: '',
    })
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [viewSheet, setViewSheet] = useState(false)
    const tooltip = (name) => {
        return (
            <Tooltip id="tooltip">
                <strong>{name}</strong>
            </Tooltip>
        );
    };
    useEffect(() => {
        if (form.search.length > 0) {
            search()
        } else {
            fetchNfes();
        }
    }, [page]);
    useEffect(() => {
        getTotalMissingMonthlyTaxInovices()
    }, [])

    async function getTotalMissingMonthlyTaxInovices() {
        setLoading(true)
        const d = new Date();
        let month = d.getMonth() + 1;
        let year = d.getFullYear();
        const result = await taxInvoicesService.count('month=' + month + '&year=' + year);
        setLastEmp(result.data.count)
        const resultEmp = await employeeService.list();

        const totalUsers = resultEmp.data.list.filter(e => e.role === 'EMP').length;

        setTotalEmp(totalUsers)
        setLoading(false)
    }
    async function fetchNfes() {
        setLoading(true)
        // const stringData = form.employee.reduce((result, item) => {
        //     return `${result}${item}|`
        // }, "")

        // const sharedWith = stringData.slice(0, -1)
        try {
            const populates = 'userId'
            const result = await taxInvoicesService.list(`page=${page}&populate=${populates}`);
            // const result = await taxInvoicesService.list(`page=${page}&sharedUsers=${sharedWith}&populate=${populates}`);
            setPaginationData(result.data)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            toast.error(err)
        }
    }
    function selectFilterEmployee(e) {
        if (e) {
            const ev = []
            e.map((item) => {
                ev.push(item.id)
            })

            setForm((prevState) => ({
                ...prevState,
                employee: ev
            }));
        }
    }
    async function search() {

        if (form.search.length > 0) {
            setLoading(true)
            const stringData = form.employee.reduce((result, item) => {
                return `${result}${item}|`
            }, "")

            const sharedWith = stringData.slice(0, -1)
            try {
                const populates = 'companyId:name,id'
                const result = await taxInvoicesService.list('page=' + page + '&filename=' + form.search + '&sharedUsers=' + sharedWith + '&populate=' + populates);
                setPaginationData(result.data)
                setLoading(false)
            } catch (err) {
                setLoading(false)
                toast.error(err)
            }
        } else {
            fetchNfes()
        }
    }

    async function downloadFile(filename, userId) {
        try {
            window.open('http://api.onework.com.br/' + filename)
            // const searchForFile = filename.split('/').reverse()[0];
            // const file = await taxInvoicesService.getDocument(searchForFile, userId);
            // // window.open(file)

            
            // const href = URL.createObjectURL(file);
            

            // // create "a" HTML element with href to file & click
            // const link = document.createElement('a');
            // link.href = href;
            // link.setAttribute('download', searchForFile); //or any other extension
            // document.body.appendChild(link);
            // link.click();

            // // clean up "a" element & remove ObjectURL
            // document.body.removeChild(link);
            // URL.revokeObjectURL(href);
        } catch (err) {
            console.log(err);
        }
    }

    function NothingFound() {
        return (
            <div>
                <div className="notFound">
                    <h4>Não encontramos nenhuma NFe</h4>
                    <br />
                    <img alt="No Data" src="/no-nf.jpeg" className="blend-img" width={400} />
                </div>
            </div>
        );
    }
    function handleChange(e) {
        setForm((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }
    function viewNfe(data) {
        setSelectedNF(data)
        handleShow()
    }

    function changeView() {
        setLoadingView(true)
        const timer = setTimeout(() => {
            setViewSheet(!viewSheet)
            setLoadingView(false)
        }, 1500);
        return () => clearTimeout(timer);

    }
    function ListAndPaginate() {
        return (
            <Table hover className="rounded-table">
                <thead>
                    <tr>
                        {/* <th>Colaborador</th> */}
                        <th>Filename</th>
                        <th>Valor</th>
                        <th>Mês de referência</th>
                        <th>Data de upload</th>
                        <th style={{ width: '250px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {paginationData.list.map((item, i) => {
                        return (
                            <tr key={i}>
                                {/* <td>{item.userId.name}</td> */}
                                <td>{item.filename}</td>
                                <td>{money(item.value)}</td>
                                <td>{findMonth(item.month)}</td>
                                <td>{formatDate(item.updatedAt)}</td>
                                <td>

                                    <Col className='action-buttons'>
                                        <OverlayTrigger placement="top" overlay={tooltip("Download")}>
                                            <a href="#" onClick={() => downloadFile(item.document_uri, item.userId)}>
                                                <svg height={20} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                </svg>
                                            </a>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="top" overlay={tooltip("Dados")}>
                                            <a href="#" onClick={() => viewNfe(item)}>
                                                <svg height={20} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                                </svg>

                                            </a>
                                        </OverlayTrigger>

                                    </Col>


                                </td>
                            </tr>
                        )
                    })}

                </tbody>
            </Table>
        );
    }
    function ListColumns() {
        return (<>
            {paginationData.list.map((item, i) => {
                return (
                    <Col md={4} lg={2} xl={2} sm={6} xs={12} className='mb-4'>
                        <Card actions={[{
                            label: 'Baixar',
                            action: () => downloadFile(item.document_uri, item.userId)
                        },
                        {
                            label: 'Dados',
                            action: () => viewNfe(item)
                        }]}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                            </svg>
                            <Badge bg={'success-ow'}>{findMonth(item.month)}/{item.year}</Badge>
                            <div className="card-bottom">
                                <Row>

                                    <Col>
                                        {/* <h6>{item.userId.name}</h6> */}
                                        <small>{money(Number(item.value))}</small>
                                       
                                    </Col>
                                </Row>

                            </div>
                        </Card>
                    </Col>
                )
            })}

        </>
        )
    }

    return (
        <div className="dashboard mt-5">
            <>
                {/* <Tour initialRun={true} type='NFE' /> */}
                {!userData.companyData.nextTaxInvoiceSendIn && <Badge bg={'warn'} className='mb-5'>
                    <svg className="w-6 h-6 mx-2 mr-2" xmlns="http://www.w3.org/2000/svg" height={25} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
                    </svg>
                    Para que seus colaboradores possam enviar Notas Fiscais, você precisa configurar seus parâmetros de recebimento. <Link to='/config'>Clique aqui para configurar.</Link>
                </Badge>}
                <Row>
                    <Col md={9}>
                        <h2>Notas Fiscais</h2>
                        <p>Visualize a organize melhor as NFes de seus colaboradores.</p>

                    </Col>

                </Row>

                <Row>
                    <Col md={4}>
                        <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                            <Form.Control type="text" placeholder="Pesquisar" name="search" className="input" onChange={handleChange} defaultValue={form.search} />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <SelectEmployee name="employee" onChange={selectFilterEmployee} id='2' />

                    </Col>
                    <Col md={3}>
                        <ButtonOw type={'button'} variant={'secondary'} onClick={search}>Filtrar</ButtonOw>
                    </Col>
                    <Col md={2}>
                            {!viewSheet ? (
                                <OverlayTrigger
                                    placement="left"
                                    overlay={tooltip('Visualizar como Tabela')}>
                                    <div>
                                        <ButtonOw
                                            type={'button'}
                                            variant={'white'}
                                            onClick={changeView}>
                                            <svg
                                                width={18}
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                class="size-6">
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
                                            </svg>

                                        </ButtonOw>
                                    </div>
                                </OverlayTrigger>
                            ) : (
                                <OverlayTrigger
                                    placement="left"
                                    overlay={tooltip('Visualizar como Card')}>
                                    <div>
                                        <ButtonOw
                                            type={'button'}
                                            variant={'white'}
                                            onClick={changeView}>
                                            <svg 
                                            width={18}
                                            xmlns="http://www.w3.org/2000/svg" 
                                            fill="none" 
                                            viewBox="0 0 24 24" 
                                            stroke-width="1.5" 
                                            stroke="currentColor" 
                                            class="size-6">
                                                <path 
                                                stroke-linecap="round" 
                                                stroke-linejoin="round" 
                                                d="M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3" />
                                            </svg>

                                        </ButtonOw>
                                    </div>
                                </OverlayTrigger>
                            )}
                        </Col>

                </Row >
                <Row className="mt-3 mb-2">
                    {/* <Col>
                        <small>Você possui {paginationData.totalRegisters} notas fiscais</small></Col> */}
                </Row>
                <Row className="mt-3 mb-5">
                    <Col>
                        
                        {pendenciesTax > 0 ? <Badge bg="danger1"><svg width={25} style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                        </svg>
                            {pendenciesTax}{pendenciesTax > 1 ? ' Colaboradores ainda não enviaram suas NFes esse mês.' :
                                ' Colaborador ainda não enviou sua NFe esse mês.'} </Badge> :
                            <Badge bg="success-ow"><svg width={25} style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                            </svg>
                                Todos os colaboradores já enviaram suas NFes esse mês.</Badge>}</Col>
                </Row>

                <Row className="mt-4">
                    {loadingView || loading ? <div className="d-flex justify-content-center align-itens-center mt-5 mb-5">
                        <Loading variant='secondary' />
                    </div> : paginationData.totalRegisters === 0 ? NothingFound() : viewSheet ? ListAndPaginate() : ListColumns()}
                </Row>
                <Row>
                    <Col md={12} className='d-flex justify-content-center align-itens-center'>
                        <PaginationOw totalPages={paginationData.totalPages} currentPage={page} onClick={setPage} />
                    </Col>
                </Row>
                <Offcanvas show={show} onHide={handleClose} placement='end'>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Dados referente a nota fiscal #{selectedNF.id}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>



                        <Row className="paymentData">
                            <h5>Pagamento</h5>
                            <Col md={12} lg={12}>

                                <b>Chave PIX:</b> {selectedNF.userId?.bankData?.pixKey}
                            </Col>
                            <Col md={6} lg={6} className='mt-3'>
                                <b>Banco:</b> {selectedNF.userId?.bankData?.bankName}({selectedNF.userId?.bankData?.bankCode})
                            </Col>
                            <Col md={6} lg={6} className='mt-3'>
                                <b>Conta:</b> {selectedNF.userId?.bankData?.bankAccount}-{selectedNF.userId?.bankData?.bankAccountDigit}
                            </Col>
                            <Col md={6} lg={6} className='mt-3'>
                                <b>Tipo:</b> {selectedNF.userId?.bankData?.bankAccountType}
                            </Col>
                            <Col md={6} lg={6} className='mt-3'>
                                <b>Favorecido:</b> {selectedNF.userId?.bankData?.bankFavoured}
                            </Col>
                            <Col md={6} lg={6} className='mt-3'>
                                <b>Documento:</b> {selectedNF.userId?.bankData?.document}
                            </Col>
                            <Col md={12} lg={12} className='mt-3'>
                                <b>Valor da nota:</b> {money(selectedNF.value)}
                            </Col>
                        </Row>

                    </Offcanvas.Body>
                </Offcanvas>
            </>
        </div>
    );
}
